import React, { useState } from 'react'
import {FaHome, FaInstagram, FaFacebook, FaLinkedin, FaWhatsapp} from 'react-icons/fa'
import {BiMenuAltRight} from 'react-icons/bi'

import './Header.css'
// import { Link } from 'react-router-dom'/

import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Header = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  let subtitle;

  function openModal() {
    setIsOpen(true);
  }
  // openModal()
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  // let isHide = true
  const [isHide, setisHide] = useState(true);


const menuHandler = (e)=>{
  // e.prevent
    // console.log("true")

    if(isHide){
      document.getElementsByClassName("menuContainer")[0].classList.remove("hide")
      document.getElementsByClassName("menuContainer")[0].classList.add("show")
      setisHide(false)
    }
    else{
         document.getElementsByClassName("menuContainer")[0].classList.add("hide")
       document.getElementsByClassName("menuContainer")[0].classList.remove("show")

      setisHide(true)
    }
}

  return (
    <div className='HeaderContainerMain'>

<div>
      {/* <button onClick={openModal}>Open Modal</button> */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='modal-box'>

        <h2 ref={(_subtitle) => (subtitle = _subtitle)} >GET BROCHURE</h2>
          <a href="">Download</a>
          <button onClick={closeModal}>close</button>
        </div>
        
      </Modal>
    </div>



        <header>
        <div className="HeaderContainer">
            <div className="logoBox">
                <img src={require("../../images/Logo.avif")} width="150" alt="logo"/>
                {/* <h1 className='logoColor'>Fragrens </h1> */}
            </div>
            <div className="linkBox">
              <a rel="noreferrer" target="_blank" href="https://www.thefragrens.com" >STORE</a>
              {/* <a rel="noreferrer" target="_blank"  href="https://thefragrens.com">PRODUCTS</a> */}
              {/* <a rel="noreferrer" target="_blank" href="">CONTACT</a> */}
              {/* <a href="/">GET BROCHURE</a> */}
              <span  onClick={openModal} className='a-Tag'>GET BROCHURE</span>
              
            
            </div>
            <div className="iconBox">
                {/* <a href="/"><FaHome/></a> */}
                {/* <a href="https://instagram.com/thefragrens?igshid=YmMyMTA2M2Y="><FaInstagram/></a> */}
                <a href="https://www.linkedin.com/showcase/thefragrens/?viewAsMember=true"><FaLinkedin/></a>
                <a rel="noreferrer"  target='_blank' href={"https://wa.me/9953179645"}><FaWhatsapp/></a>
                {/* <a href="https://m.facebook.com/thefragrens"><FaFacebook/></a> */}
                {/* <a href="#"></a> */}
                {/* <Link to='/contect'>Contect</Link> */}
            </div>
            <div onClick={()=>menuHandler()} className="menu">
                
                <BiMenuAltRight/>
            </div>
        </div>

        <div className="menuContainer hide">
        <div>
              <a rel="noreferrer" target="_blank" href="https://www.thefragrens.com" >STORE</a>
              {/* <a rel="noreferrer" target="_blank"  href="https://thefragrens.com">PRODUCTS</a> */}
              {/* <a rel="noreferrer" target="_blank" href="">CONTACT</a> */}
              {/* <a   onClick={openModal}>GET BROCHURE</a> */}
              <span  onClick={openModal} className='atagMenu'>GET BROCHURE</span>

              
        </div>
        <div className="iconBoxx">

                {/* <a href="/"><FaHome/></a> */}
                {/* <a href="https://instagram.com/thefragrens?igshid=YmMyMTA2M2Y="><FaInstagram/></a> */}
                <a href="https://www.linkedin.com/showcase/thefragrens/?viewAsMember=true"><FaLinkedin/></a>
                <a rel="noreferrer"  target='_blank' href={"https://wa.me/9953179645"}><FaWhatsapp/></a>

                {/* <a href="https://m.facebook.com/thefragrens"><FaFacebook/></a> */}
        </div>
        </div>
    </header>

    </div>
  )
}

export default Header