import React from "react";
import "./productTypes.css";
const ProductTypes = () => {
  return (
    <>
      <div className="ProductTypesContainer">
        <div className="productTypesRow">
          <div className="productTypesItem">
            <div className="productTypesItemBox productTypesItemBoxUrl1">
              <span>
                <h2>Specialized Fragrance for your products</h2>
                {/* <span>Learn more</span> */}
              </span>
            </div>
          </div>
          <div className="productTypesItem">
            <div className="productTypesItemBox productTypesItemBoxUrl3">
              <span>
                <h2>Private Labeling Your Aromatherapy Brand</h2>
                {/* <span>Learn more</span> */}
              </span>
            </div>
          </div>
          
        </div>
        <div className="productTypesRow">
          <div className="productTypesItem">
            <div className="productTypesItemBox productTypesItemBoxUrl2">
              <span>
                <h2>
                Designing Olfactive Experience in house
                </h2>
                {/* <span>Learn More</span>  */}
              </span>
            </div>
          </div> 
           <div className="productTypesItem">
            <div className="productTypesItemBox productTypesItemBoxUrl4">
              <span>
                <h2>Corporate Gifting</h2>
                 {/* <span>Learn more</span>  */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTypes;
