import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from '../src/Components/Home/Home.js';
// import Contect from '../src/Components/Contect/Contect';
import Header from '../src/Components/Header/Header.js';
import Footer from '../src/Components/Footer/Footer.js';
import WebFont from 'webfontloader';
import Boutique from '../src/Components/Boutique/Boutique.js';

function App() {
  WebFont.load({
  google: {
    families: [ 'sans-serif', 'Roboto', 'Roxborough']
  }
});

  return (
    <BrowserRouter>
    <Header />
      <Routes>
         <Route path="/" element={<Home />} />
      </Routes>
    <Footer />

    </BrowserRouter>
  );
}

export default App;
